<template>
  <div>
    <!--    新生儿游泳-->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />
        添加就诊人
      </div>
    </van-popup>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.1)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button
        class="cancle-btn"
        to="/treatmentServices"
        v-if="$route.query.personType == 0"
        >取消</van-button
      >
      <van-button
        class="cancle-btn"
        @click="overflowShow1 = false"
        v-if="$route.query.personType == 1"
        >关闭</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
    <div class="promise-item">
      <van-row>
        <van-col span="7">就诊人：</van-col>
        <van-col span="17">{{ chooseItem.name }}</van-col>
      </van-row>
      <van-row>
        <van-col span="7">就诊卡号：</van-col>
        <van-col span="17">{{ chooseItem.patId }}</van-col>
      </van-row>
      <van-row>
        <van-col span="7">执行科室：</van-col>
        <van-col span="17">新生儿</van-col>
      </van-row>
      <van-row>
        <van-col span="7">服务点：</van-col>
        <van-col span="17">河南省第二人民医院本部</van-col>
      </van-row>
      <van-divider />
      <van-row>
        <van-cell title="服务项目" value="" label="新生儿游泳">
          <template #title>
            <span>服务项目</span>
          </template>
          <template #default>
            <span style="color: #f00">￥{{ (price / 100).toFixed(2) }}*1</span>
          </template>
          <template #label>
            <span>新生儿游泳</span>
          </template>
        </van-cell>
      </van-row>
    </div>
    <van-submit-bar :price="price" label="付款金额：">
      <template #button>
        <van-button
          type="primary"
          color="#4AC691"
          @click="onSubmit"
          class="button-confirm"
          >确认</van-button
        >
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
export default {
  name: "neonatalInfoConfirm",
  data() {
    return {
      showPopup: false, // 弹出层
      list: [],
      personId: "",
      chooseItem: "",
      overflowShow1: false,
      price: 6600,
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.overflowShow1 = true;
        return false;
      }
      return true;
    },
    /**
     * 点击就诊人
     */
    openChooise() {
      this.showPopup = true;
    },
    /**
     * 添加就诊人
     */
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    /**
     * 选择就诊人
     */
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
    },
    /**
     * 获取就诊人信息的方法
     */
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.loading = false;
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.isHasPatient();
            sessionStorage.setItem(
              "CurrentJZPerson",
              JSON.stringify(this.chooseItem)
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    /**
     * 点击支付
     */
    onSubmit() {
      this.$router.push({
        path: "/neonatalWxPayPage",
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}

.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}

.promise-item {
  width: 9rem;
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 0.35rem;
  line-height: 0.55rem;
  background-color: #fff;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}

.promise-item .van-row .van-col:first-child {
  text-align: right;
}
.button-confirm {
  width: 4rem;
  height: 100%;
  font-size: 0.45rem;
}
/deep/ .van-submit-bar__bar {
  padding: 0;
}
</style>
